<template lang="pug">
  div.full-width
    span.full-width.pill.pl-2.d-flex.items-center.my-2(v-for="(star,key) in stars" :key="key" :class="{'bigger':bigger}")
      span.align-center.d-flex(v-for="(item,_key) in star" :key="_key")
        img(v-for="(el, elKey) in item" :key="elKey" :src="img.singleStar")
        span.px-2(v-if="star.length > 1 && _key < 1") #[translate y]
</template>

<script>
export default {
  props: {combination: {type: Array}, bigger: {type: Boolean}},
  data() {
    return {
      img: {
        singleStar: require('@/assets/img/single-star.svg'),
      }
    }
  },
  computed: {
    stars() {
      return this.combination.map(item => {
        let _item = item
        if (typeof _item === "number") {
          _item = item.toString()
        }
        return _item.split("-").map(el => {
          return Array.from({length: el}, (_, i) => i + 1)
        })
      })
    }
  },
}
</script>

<style scoped>
.pill {
  border: solid 1px #DFE3E6;
  border-radius: 12px;
  min-height: 22px;
}

.bigger {
  padding: 10px;
  background-color: white
}

.full-width {
  width: fit-content;
  padding: 0 10px;
}
</style>
