import { render, staticRenderFns } from "./AnswersChatGPT.vue?vue&type=template&id=fe4e1e4e&scoped=true"
import script from "./AnswersChatGPT.vue?vue&type=script&lang=js"
export * from "./AnswersChatGPT.vue?vue&type=script&lang=js"
import style0 from "./AnswersChatGPT.vue?vue&type=style&index=0&id=fe4e1e4e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe4e1e4e",
  null
  
)

export default component.exports