<template lang="pug">
  .filter-top.d-flex.flex-wrap
    v-col.col-brand.mr-1.pl-0(v-if="IsPizzasComparator")
      v-select.pt-0.selectFilterGP(v-model="brandsPizzaSelected" :items="brandsPizza" item-value="id" :single-line="true" color="terciary" item-color="#ECF2F9" :menu-props="{offsetY: true }")
        template(slot="append")
          v-icon(color="terciary") mdi-chevron-down
        template(v-slot:selection="{ item, index }")
          img(:src="item.logo" width="38" height="19")
          span.terciary--text.text-uppercase {{ item.name }}
        template(v-slot:item="{ item }")
          img.mr-2.mb-2.img-option(:src="item.logo" width="79" height="42")
          span.option-filter-brand {{ item.name }}
    v-col.col-brand.mr-1.pl-0(v-if="!IsPizzasComparator")
      v-select.pt-0.selectFilterGP(v-model="brandSelect" :items="brands" :single-line="true" color="terciary" item-color="#ECF2F9" :menu-props="{offsetY: true }" @change="changeBrand")
        template(slot="append")
          v-icon(color="terciary") mdi-chevron-down
        template(v-slot:selection="{ item, index }")
          img(:src="item.logo" width="38" height="19")
          span.terciary--text.text-uppercase {{ item.name }}
        template(v-slot:item="{ item }")
          img.mr-2.mb-2.img-option(:src="item.logo" width="79" height="42")
          span.option-filter-brand {{ item.name }}
    v-col.col-province.mr-1.pl-0(v-if="type !== 'provincesRank'")
      v-select.pt-0.selectFilterGP(v-model="filters.province" :items="provinces" item-text="province_name" return-object :placeholder="placeholder.province" no-data-text="no hay provincias para esta marca" color="terciary" item-color="#ECF2F9" :menu-props="{offsetY: true }" :disabled="!brandSelect" @change="changeFilter('province')" clearable)
        template(slot="append")
          v-icon(color="terciary") mdi-chevron-down
        template(v-slot:selection="{ item, index }")
          span.terciary--text.text-uppercase.text-elipsis {{ item.province_name }}
        template(v-slot:item="{item }")
          span.option-filter( :class="{'selected' : filters.province.province_name === item.province_name}") {{ item.province_name }}
    v-col.col-location.mr-1.pl-0(v-if="type !== 'provincesRank' && type !== 'rateComparatorPizzas'")
      v-select.pt-0.selectFilterGP(v-model="filters.city" :items="cities" item-text="city_name" return-object :placeholder="placeholder.location" no-data-text="no hay ciudades para esta provincia"  color="terciary" item-color="#ECF2F9" :menu-props="{offsetY: true }" @change="changeFilter('city')" :disabled="IsPizzasComparator || !filters.province" clearable)
        template(slot="append")
          v-icon(color="terciary") mdi-chevron-down
        template(v-slot:selection="{ item, index }")
          span.terciary--text.text-uppercase.text-elipsis {{ item.city_name }}
        template(v-slot:item="{ item }")
          span.option-filter {{ item.city_name }} ({{ item.center_count }})
    v-col.col-family.mr-1.pl-0
      v-select.pt-0.selectFilterGP(v-model="filters.family" :items="families" item-text="name" return-object :placeholder="placeholder.family" no-data-text="no hay familias" color="terciary" item-color="#ECF2F9"  :disabled="IsPizzasComparator" @change="changeFilter('family')" :menu-props="{offsetY: true }" clearable)
        template(slot="append")
          v-icon(color="terciary") mdi-chevron-down
        template(v-slot:selection="{ item, index }")
          span(v-if="item").terciary--text.text-uppercase.text-elipsis {{ item.name }}
        template(v-slot:item="{item }")
          span.option-filter(v-if="item" :class="{'selected' : filters.family.name === item.name}") {{ item.name }}
    v-col.col-channel.mr-1.pl-0(v-if="type!=='aggregators' && !['urbanpoke'].includes(nameInstance)")
      v-select.pt-0.selectFilterGP(v-model="filters.channel" :items="channels" item-text="name" return-object :placeholder="placeholder.channel"  color="terciary" item-color="#ECF2F9" :menu-props="{offsetY: true }" :disabled="IsPizzasComparator || !brandSelect" @change="changeFilter('channel')" clearable)
        template(slot="append")
          v-icon(color="terciary") mdi-chevron-down
        template(v-slot:selection="{ item, index }")
          span.terciary--text.text-uppercase.text-elipsis {{ item.name }}
        template(v-slot:item="{ item }")
          span.option-filter {{ item.name }}
    v-col.col-aggregator.pl-0(v-if="type==='comparator' || type === 'provincesRank'")
      v-select.pt-0.selectFilterGP(v-model="filters.aggregator" :items="aggregators" item-text="name" return-object :placeholder="placeholder.Aggregator"  color="terciary" item-color="#ECF2F9" :menu-props="{offsetY: true }" :disabled="IsPizzasComparator || !brandSelect || filters.channel.name !== 'Delivery'" @change="changeFilter('aggregator')" clearable)
        template(slot="append")
          v-icon(color="terciary") mdi-chevron-down
        template(v-slot:selection="{ item, index }")
          span.terciary--text.text-uppercase.text-elipsis {{ item.name }}
        template(v-slot:item="{ item }")
          span.option-filter {{ item.name }}
</template>

<script>
import SrvGastroPricing from '@/services/gastropricing.js'
import commonMixins from "@/mixins/common.js";

export default {
  props: ['type'],
  mixins: [commonMixins],
  data() {
    return {
      img: {
        pizzaInactive: require('@/assets/img/ico_pizza_azul.svg'),
        pizzaActive: require('@/assets/img/ico_pizza_blanco.svg'),
        download: require('@/assets/img/ico_descarga.svg'),
        closePizzas: require('@/assets/img/ico_cerrar_pizzas.svg')
      },
      brands: [],
      brandsPizza: [],
      brandsPizzaSelected: {
        id: 378
      },
      provinces: [],
      cities: [],
      cps: [],
      families: [],
      channels: [],
      aggregators: [],
      filters: {
        province: '',
        family: '',
        channel: '',
        aggregator: '',
      },
      brandSelect: '',
      placeholder: {
        brand: this.$gettext('BRAND'),
        province: this.$gettext('PROVINCE'),
        location: this.$gettext('LOCATION'),
        postal_code: this.$gettext('POSTAL CODE'),
        family: this.$gettext('FAMILY'),
        channel: this.$gettext('CHANNEL'),
        Aggregator: this.$gettext('AGGREGATORS')
      }
    }
  },
  async created() {
    if (!this.$store.getters.hasBrand) {
      await SrvGastroPricing.getCountries().then(async res => {
        if (res.status === 200) {
          const result = res.data.filter(c => c.code == window.localStorage.getItem('_country_selected_gp'))
          if (result.length > 0) {
            window.localStorage.setItem('_country_selected_gp', result[0].code)
          } else {
            window.localStorage.setItem('_country_selected_gp', res.data[0].code)
          }
          window.localStorage.setItem('_countries_gp', JSON.stringify(res.data))
          await this.getBrands()
        }
      })
      this.$emit("getData", {brand: this.$store.getters.hasBrand.id, filters: this.filters});
    } else {
      await this.getBrands()
      this.brandSelect = this.$store.getters.hasBrand
      this.provinces = this.$store.getters.hasProvinces
      this.channels = this.$store.getters.hasChannels
      if (this.$store.getters.hasFamilies === '') {
        this.getFamilies()
      }
      this.families = this.$store.getters.hasFamilies
      this.aggregators = this.$store.getters.hasAggregators
      if (this.$store.getters.hasProvince) {
        this.filters.province = this.$store.getters.hasProvince
        this.cities = this.$store.getters.hasCities
        if (this.$store.getters.hasCity) {
          this.filters.city = this.$store.getters.hasCity
          this.cps = this.$store.getters.hasPostalCodes
          if (this.$store.getters.hasPostalCode) {
            this.filters.municipality = this.$store.getters.hasPostalCode
          }
        }
      }
      if (this.$store.getters.hasFamily) {
        this.filters.family = this.$store.getters.hasFamily
      }
      if (this.$store.getters.hasChannel) {
        this.filters.channel = this.$store.getters.hasChannel
      }
      if (this.$store.getters.hasAggregator) {
        this.filters.aggregator = this.$store.getters.hasAggregator
      }
      this.$emit("getData", {brand: this.$store.getters.hasBrand.id, filters: this.filters});

    }

  },
  computed: {
    IsPizzasComparator() {
      if (this.$route.name === 'rateComparatorPizzas') {
        return true
      }
      return false
    },
    paramCOD() {
      return this.$route.params.paramCOD
    }
  },
  methods: {
    downloadExcelPizzas() {
      this.$emit('downloadExcelPizzas', this.brandSelect.id)
    },
    async getBrands() {
      await SrvGastroPricing.getBrands()
        .then(async res => {
          if (res.status === 200) {
            if (['aggregatorsRank', 'provincesRank'].includes(this.$router.history.current.name)) {
              this.brands = [...res.data.brands, {header: 'Marcas competidoras'}, ...res.data.competitors]
            } else {
              this.brands = res.data.brands
            }
            this.brandsPizza = this.brands.find((element) => {
              if ([378].includes(parseInt(element.id))) {
                return element
              }
            })
            this.brandsPizza = [this.brandsPizza]
            this.$store.commit('GP_BRANDS', this.brands)
            if (this.brands.length > 0) {
              if (this.$store.getters.hasBrand && this.brands.filter(e => e.id === this.$store.getters.hasBrand.id).length > 0) {
                this.brandSelect = this.$store.getters.hasBrand
              } else {
                this.brandSelect = this.brands[0]
              }
              this.$store.commit('GP_BRAND_SELECT', this.brandSelect)
              this.getProvinces(this.brandSelect.id)
              this.getFamilies(this.brandSelect.id)

              this.filters.channel = await this.getChannels(true)
              this.getAggregators()

              //this.$emit("getData",{brand: this.brandSelect.id, filters: this.filters});
            }
          }
        }, () => {
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
    },
    getProvinces(brand) {
      SrvGastroPricing.getProvinces(brand)
        .then(res => {
          if (res.status === 200) {
            this.provinces = res.data
            this.$store.commit('GP_PROVINCES', res.data)

          }
        }, () => {
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
    },
    getCities(province) {
      SrvGastroPricing.getCities(this.brandSelect.id, province.province_id)
        .then(res => {
          if (res.status === 200) {
            this.cities = res.data
            this.$store.commit('GP_CITIES', res.data)
          }
        }, () => {
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
    },
    getCps(city) {
      SrvGastroPricing.getCps(this.brandSelect.id, city.city_id)
        .then(res => {
          if (res.status === 200) {
            this.cps = res.data
            this.$store.commit('GP_POSTAL_CODES', res.data)
          }
        }, () => {
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
    },
    async getFamilies(brand) {
      let valAwait = ''
      await SrvGastroPricing.getFamilies(brand)
        .then(res => {
          if (res.status === 200) {
            this.families = res.data
            this.$store.commit('GP_FAMILIES', res.data)
            valAwait = res.data
            // this.families.forEach(element => {
            //   if(element.id === 2){
            //     this.familySelect = element
            //   }
            // });
          }
        }, () => {
        })
      return valAwait
    },
    async getChannels(first = false) {
      let valAwait = ''
      await SrvGastroPricing.getChannels()
        .then(res => {
          if (res.status === 200) {
            if (this.nameInstance === 'amrest') {
              this.channels = res.data.filter(e => e.name === 'Delivery')
            } else {
              this.channels = res.data
            }
            this.$store.commit('GP_CHANNELS', this.channels)
            if (first) {
              this.channels.forEach(element => {
                if (element.name === 'Delivery') {
                  this.$store.commit('GP_CHANNEL_SELECT', element)
                  valAwait = element
                }
              })
            }
          }
        }, () => {
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
      return valAwait
    },

    getAggregators() {
      SrvGastroPricing.getAggregators()
        .then(res => {
          if (res.status === 200) {
            this.aggregators = res.data
            this.$store.commit('GP_AGGREGATORS', res.data)
          }
        }, () => {
          // this.message = 'Se ha producido un error. Intentelo más tarde.'
        })
    },

    changeBrand() {
      this.$store.commit('GP_BRAND_SELECT', this.brandSelect)
      // LIMPIAR FILTROS
      this.provinces = []
      this.families = [],
        this.$store.commit('GP_PROVINCE_SELECT', '')
      this.$store.commit('GP_FAMILY_SELECT', '')
      this.$store.commit('GP_CHANNEL_SELECT', '')
      this.$store.commit('GP_AGGREGATOR_SELECT', '')
      this.filters.province = ''
      this.filters.family = ''
      this.filters.channel = ''
      this.filters.aggregator = ''
      this.getFamilies(this.brandSelect.id)
      this.getProvinces(this.brandSelect.id)
      this.$emit("getData", {brand: this.brandSelect.id, filters: this.filters})
    },
    changeFilter(type) {
      if (this.filters[type] === null) {
        this.filters[type] = ''
      }
      if (type === 'province') {
        if (this.filters.province) {
          this.getCities(this.filters.province)
        }
        this.filters.city = ''
        this.filters.municipality = ''
        this.cps = []
        this.$store.commit('GP_POSTAL_CODES', [])
      }
      if (type === 'city') {
        if (this.filters.city) {
          //this.getCps(this.filters.city)
        }
        this.filters.municipality = ''
      }
      this.$store.commit('GP_PROVINCE_SELECT', this.filters.province)
      this.$store.commit('GP_CITY_SELECT', this.filters.city)
      this.$store.commit('GP_POSTAL_CODE_SELECT', this.filters.municipality)
      this.$store.commit('GP_FAMILY_SELECT', this.filters.family)
      this.$store.commit('GP_CHANNEL_SELECT', this.filters.channel)
      this.$store.commit('GP_AGGREGATOR_SELECT', this.filters.aggregator)

      this.$emit("getData", {brand: this.brandSelect.id, filters: this.filters});
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-top {
  font-size: 0.875rem;

  .col-brand {
    flex: 0 0 260px;
    max-width: 260px;

  }

  .col-province {
    flex: 0 0 187px;
    max-width: 187px;
  }

  .col-location {
    flex: 0 0 150px;
    max-width: 150px;
  }

  .col-cp {
    flex: 0 0 170px;
    max-width: 170px;
  }

  .col-family {
    flex: 0 0 200px;
    max-width: 200px;
  }

  .col-channel {
    flex: 0 0 152px;
    max-width: 152px;
  }

  .col-aggregator {
    flex: 0 0 172px;
    max-width: 172px;
  }

  .col {
    span {
      margin-left: 5px;
      font-size: 0.750rem;
    }
  }

  #buttonsPizzaComparator {
    align-items: flex-start;
    display: flex;
    flex: 1 1 auto;
    font-size: 16px;
    letter-spacing: normal;
    max-width: 100%;
    text-align: left;
    padding-top: 4px;

    .pizzasInactive, .pizzasActive {
      border-radius: 4px;
      padding: 3px 15px 3px 5px;
      text-decoration: none;

      * {
        display: inline-block;
        vertical-align: middle;
      }
    }

    .pizzasInactive {
      background-color: #fff;
      color: #000;
    }

    .pizzasActive {
      background-color: #4EA5F5;
      color: #fff;
    }

    .downloadExcelPizzas {
      border: 1px solid #CECECE;
      background-color: #fff;
      padding: 3px 8px;
      margin-left: 10px;
    }
  }
}

.v-input--is-disabled {
  opacity: 0.6;
}

</style>
