<template lang="pug">
  .card-content
    .row.text-left.mt-2
      chart-evolution-main-number-opinion(:chartData="getChartData()" style="width:100%; height:280px" :label="label" )
</template>

<script>
import SkeletonCard from '@/components/common/skeletonCard.vue'
import ChartEvolutionMainNumberOpinion from '@/components/charts/EvolutionMainNumberOpinion.vue'
import commonMixins from "@/mixins/common.js";

export default {
  props: ['chartData', 'isDelivery', "label"],
  mixins: [commonMixins],
  components: {SkeletonCard, ChartEvolutionMainNumberOpinion},
  methods: {
    getChartData() {
      return {
        legend: [
          {
            data: [this.$gettext('Valoration')],
            left: "2%",
          },
          {
            data: [this.$gettext('Number of Opinions')],
            right: "2%",
          }
        ],
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: this.chartData.months
          }
        ],
        yAxis: [
          {
            type: "value",
            position: "left",
            min: 0,
            max: 10,
            splitLine: {show: false},
          },
          {
            type: "value",
            position: "right",
            min: 0,
          }
        ],
        series: [
          {
            name: this.$gettext('Valoration'),
            type: 'line',
            data: this.ratings,
            yAxisIndex: 0,
          },
          {
            name: this.$gettext('Number of Opinions'),
            type: 'line',
            data: this.chartData.n_reviews,
            yAxisIndex: 1
          },
        ],
      }
    }
  },
  computed: {
    ratings() {
      let numbers = this.chartData.ratings
      let numbersFormat
      if (numbers) {
        numbersFormat = numbers.map((number) => {
          return +this.$options.filters.formatNumber(number, 1, 1).replace(',', '.')
        })
      }
      return numbersFormat
    }
  }

}
</script>

<style lang="scss" scoped>
.headerChart {
  width: 100%;
  display: block;
  font-size: 0.75rem;
}

h3 {
  float: left;
  font-weight: normal;
  padding: 0 20px;
  font-size: 0.70rem;

  &.nreviews {
    float: right;
    color: #4a90e2;
  }
}
</style>
