<template lang="pug">
  div
    error-state(v-if="showError" :visible="showError" @close="showError=false" @confirmed="showError=false" :error-msg="errorMessage")
    v-dialog(v-else v-model="isActive" width="1100" persistent)
      v-card.dialogEdit
        v-container(fluid)
          section#nameContainer
            v-row.items-center.strong-divider.no-gutters.pt-4.pb-5
              v-col.d-flex.items-center(cols="11")
                img.ml-2(:src="img.config")
                strong.px-4 #[translate Nueva configuración]
                div.d-flex.flex-col.configName
                  input(type="text" v-model="configName" maxlength="54" class="rounded input" :class="{'input-error': errors.brandName}")
                  span.text-red(v-if="errors.brandName" ) #[translate Este campo es requerido]
              v-btn.icon(@click="close" class="close-button" :elevation="0")
                img(:src="img.cancel" width="23" height="23")
            v-row.divider.no-gutters.py-4
              v-col.px-2(cols="6" class="d-flex items-center")
                span.pr-2.text-13.font-weight-med {{this.translatedLevels[this.currentLevelLabel]}}
                input(type="text" v-model="marca.name" maxlength="54" class="rounded input w-full" disabled)
              v-col.px-2(cols="6" class="d-flex items-center")
                span.pr-2.text-13.font-weight-med #[translate Restaurante]
                select.selectCustom.selectCustomExt.w-full(v-if="centers" @change="handleRestaurantChange($event)" v-model="center")
                  option(v-for="(center,index) in centers" :value="center.id") {{center.name}}
          section#starsContainer
            v-row.justify-center.divider.no-gutters.px-2
              v-col.col-12.d-flex.items-center.pb-1
                span.text-subtitle-1.pr-4.font-weight-bold #[translate Opiniones]
                p.caption.text-13.mb-0 #[translate Responder a opiniones con Nº de estrellas en la opinión de:]

              v-col.col-12.no-gutters.justify-end.items-center.d-flex.flex-col
                .stars-container.d-flex.w-full.justify-space-around(style="max-width: 90%")
                  v-checkbox(v-model="ratings" label="" value="4")
                    template(v-slot:label)
                      stars-combinations(:bigger="true" :combination="['5']")
                  v-checkbox(v-model="ratings" label="" value="3")
                    template(v-slot:label)
                      stars-combinations(:bigger="true" :combination="['4']")
                  v-checkbox(v-model="ratings" label="" value="2")
                    template(v-slot:label)
                      stars-combinations(:bigger="true" :combination="['3']")
                  v-checkbox(v-model="ratings" label="" value="1")
                    template(v-slot:label)
                      stars-combinations(:bigger="true" :combination="['2-1']")
                span.text-red(v-if="errors.stars") #[translate Elije al menos una combinación]

              v-col.justify-end.items-center.d-flex
                v-row.no-gutters.col-12(style="max-width: 90%")
                  v-col.col-6.d-flex.flex-col
                    div.d-flex.gap-1em.items-center
                      span.text-13 #[translate Tipo de opiniones]
                      v-checkbox(v-model="withText" label="" :value="true")
                        template(v-slot:label)
                          span.text-13 #[translate Con texto]
                      v-checkbox(v-model="noText" label="" :value="true")
                        template(v-slot:label)
                          span.text-13 #[translate Sin texto]
                  v-col.col-6.d-flex.items-center.gap-0_5em
                    span.text-13 #[translate Categoria]
                    select.selectCustom.selectCustomExt.w-full(@change="handleCategoryChange($event)" v-model="categorySelect" :disabled="!withText")
                      option(v-for="category in categories" :value="category.id") {{category.name}}

          section#configContainer
            v-row.no-gutters
              v-col.mb-4.pa-4(cols="12")
                v-row.align-center.mb-2
                  img(:src="img.ia" class="py-2")
                  v-col
                    div.text-13.font-weight-bold #[translate Configura las respuestas automáticas generadas con IA]

                v-row.no-gutters
                  v-col(cols="4" class="d-flex items-center px-2")
                    span.pr-2.text-13 #[translate Tono de respuesta]
                    select.selectCustom.selectCustomExt.w-full(@change="handleRestaurantChange($event)" v-model="toneSelect")
                      option(v-for="tone in tones" :value="tone.id") {{tone.name}}
                  v-col(cols="4" class="d-flex items-center px-2")
                    span.pr-2.text-13 #[translate Longitud de las respuestas]
                    select.selectCustom.selectCustomExt.w-full(@change="handleRestaurantChange($event)" v-model="lengthSelect")
                      option(v-for="len in answerLenght" :value="len.id") {{len.name}}
                  v-col(cols="4" class="d-flex items-center px-2")
                    span.pr-2.text-13 #[translate Idioma]
                    select.selectCustom.selectCustomExt.w-full(@change="handleRestaurantChange($event)" v-model="languageSelect")
                      option(v-for="lang in languages" :value="lang.id") {{lang.name}}

            v-row.no-gutters.mb-2.pa-4
              v-col(cols="5")
                span.mb-2.font-weight-bold.text-13 #[translate Palabras clave]
                p.text-13 #[translate Puedes incluir hasta 5 palabras (keyword) para que la IA las incluya en su respuesta para mejorar el SEO.]
                p.text-13 #[translate Ejemplo: "burger", "Madrid", "mejores", "carne", "caseras"...]
              v-col.pt-6.pl-2(cols="7")
                v-row.no-gutters.align-center.mb-2
                  v-col.d-flex.items-center
                    input(type="text" v-model="newKeyword" placeholder="Añadir palabra clave" maxlength="54" class="rounded input" @keyup.enter="addKeyword")
                    v-btn(color="primary" @click="addKeyword" :disabled="keywords.length >= 5" class="ml-2") #[translate Añadir palabra]
                v-chip-group
                  v-chip(v-for="keyword in keywords" :key="keyword" close @click:close="removeKeyword(keyword)") {{ keyword }}

            v-row.no-gutters.px-4
              v-col(cols="12")
                span.mb-2.font-weight-bold.text-13 #[translate Incluir en la respuesta]
              v-col.d-flex(cols="12")
                v-checkbox.col-3(v-model="googleText")
                  template(v-slot:label)
                    span.text-13 #[translate El nombre del usuario de Google]
                v-checkbox.col-3(v-model="brandName")
                  template(v-slot:label)
                    span.text-13 #[translate El nombre del negocio]
                div.d-flex.gap-1em.col-6.items-center
                  v-checkbox(v-model="email")
                    template(v-slot:label)
                      span.text-13 #[translate Nuestro email de contacto]
                  div.w-full
                    input(type="text" v-model="contactEmail" maxlength="54" class="rounded input w-full" :class="{'input-error': errors.email}")
                    span.text-red(v-if="errors.email" ) #[translate Este campo es requerido]

            v-row.mb-4.px-4.strong-divider.no-gutters
              v-col(cols="5")
                span.text-subtitle-1.mb-2.font-weight-bold.text-13 #[translate Entidad de Marca]
                p.text-13 #[translate Introduce una descripción de marca para que las respuestas de la IA sean lo más acertadas posibles. (max. 450 caracteres)]
              v-col.pt-6.pl-2(cols="7")
                textarea.w-full(v-model="brandDescription" placeholder="Descripción de marca" maxlength="450" rows="3")
                span.text-red(v-if="errors.brandDescription" ) #[translate Este campo es requerido]

          v-row.justify-center.col-12.no-gutters.gap-0_5em
            v-col.col-3(v-if="!loading")
              button.outlined-button(@click="close")
                span.span.text-13 #[translate Cancelar]
            v-col.col-3.d-flex.justify-center
              v-progress-circular.d-flex.justify-center(v-if="loading" indeterminate color="primary")
              button.primary-button(v-else @click="save")
                span.span.text-13 #[translate Guardar]
</template>

<script>
import StarsCombinations from "@/components/feedback/StarsCombinations.vue";
import common from "@/mixins/common";
import FeedbackSrv from "@/services/feedback";
import ErrorState from "@/components/local/errorState.vue"

export default {
  mixins: [common],
  props: {
    isActive: {type: Boolean, default: false},
    initialData: {type: Object, default: null},
  },
  components: {
    StarsCombinations, ErrorState
  },
  data() {
    return {
      img: {
        config: require("@/assets/img/feedback/edit-config.svg"),
        cancel: require("@/assets/img/feedback/cancel.svg"),
        ia: require("@/assets/img/feedback/ia.svg"),
      },
      center: null,
      configName: '',
      marca: {name: '', id: ''},
      ratings: [],
      opinionTypes: [],
      toneSelect: 'neutral',
      lengthSelect: 'medium',
      languageSelect: 'original',
      newKeyword: '',
      keywords: [],
      brandDescription: '',
      withText: true,
      noText: false,
      googleText: false,
      brandName: false,
      email: false,
      errorMessage: "",
      categorySelect: null,
      contactEmail: "",
      currentItem: null,
      currentLevelLabel: 'center',
      translatedLevels: {
        zone: this.$gettext('Zona'),
        brand: this.$gettext('Marca'),
        subzone: this.$gettext('Región'),
        local: this.$gettext('Centro'),
        center: this.$gettext('Centro'),
      },
      centers: [],
      tones: [
        {name: this.$gettext('Formal'), id: 'formal'},
        {name: this.$gettext('Neutral'), id: 'neutral'},
        {name: this.$gettext('Cercano/Informal'), id: 'informal'},
      ],
      answerLenght: [
        {name: this.$gettext('Larga'), id: 'long'},
        {name: this.$gettext('Mediana'), id: 'medium'},
        {name: this.$gettext('Corto'), id: 'short'},
      ],
      languages: [
        {name: this.$gettext('Original'), id: 'original'},
        {name: this.$gettext('Español'), id: 'spanish'},
        {name: this.$gettext('English'), id: 'english'},
      ],
      errors: {
        email: false,
        brandName: false,
        brandDescription: false,
        stars: false,
        hasText: false
      },
      loading: false,
      categories: [],
      showError: false
    }
  },
  async mounted() {
    if (this.initialData) this.setInitialData()
    this.categories = [{name: "N/A", id: null}, ...this.$store.getters.getCategories?.internet?.categories]
    const activeNode = document.querySelector(".tree-active");
    this.currentNode = this.getCurrentNode({slug: activeNode.dataset.slug})?.[0]

    for (const level in this.level) {
      if (this.level[level]) {
        this.currentLevelLabel = level
      }
    }

    this.centers = [{id: null, name: "N/A"}, ...this.getAllCenterFromNode(this.currentNode)]
    this.marca = {name: this.currentNode.name, id: this.currentNode.id}
  },
  methods: {
    setInitialData() {
      const form = {
        "name": "configName",
        "center": "center",
        "node": "node",
        "has_text": "withText",
        "brand_description": "brandDescription",
        "category": "categorySelect",
        "keywords": "keywords",
        "ratings": "ratings",
        "tone": "toneSelect",
        "language": "languageSelect",
        "length": "lengthSelect",
        "include_google_username": "googleText",
        "include_business_name": "brandName",
        "contact_email": "contactEmail"
      }

      const formKeys = Object.keys(form);

      formKeys.forEach(key => {
        if (key === 'contact_email') {
          if (this.initialData[key]) {
            this.email = true
            this.contactEmail = this.initialData[key]
          }
        } else if (key === 'category') {
          if (this.initialData[key]) {
            this.categorySelect = +this.initialData[key]
          }
        } else if (['center', 'node'].includes(key)) {
          if (this.initialData[key]) {
            this.center = this.initialData[key].id
          }
        } else {
          this[form[key]] = this.initialData[key]
        }
      })
    },
    handleRestaurantChange() {
    },
    handleCategoryChange() {
    },
    addKeyword() {
      if (this.newKeyword && this.keywords.length < 5) {
        this.keywords.push(this.newKeyword)
        this.newKeyword = ''
      }
    },
    removeKeyword(keyword) {
      this.keywords = this.keywords.filter(k => k !== keyword)
    },
    scrollTo(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({behavior: 'smooth'});
      }
    },
    close() {
      this.$emit("onHidden")
      this.resetErrors()
    },
    resetErrors() {
      this.errors = {
        email: false,
        brandName: false,
        brandDescription: false,
        stars: false,
        hasText: false
      }
    },
    async save() {
      this.errorMessage = ''
      this.resetErrors()
      if (!this.configName) {
        this.scrollTo('nameContainer')
        return this.errors.brandName = true
      }
      if (!this.ratings.length) {
        this.scrollTo('starsContainer')
        return this.errors.stars = true
      }
      if (!this.brandDescription || this.brandDescription.length > 450) {
        this.scrollTo('configContainer')
        return this.errors.brandDescription = true
      }
      if (this.email && !this.contactEmail) {
        this.scrollTo('configContainer')
        return this.errors.email = true
      }
      this.loading = true

      let _center = null
      if (this.center) {
        _center = this.center
      } else {
        if (this.marca.id && this.currentLevelLabel === 'center') {
          _center = this.marca.id
        }
      }

      const form = {
        "name": this.configName.trim(),
        "center": _center,
        "node": this.center || this.currentLevelLabel === 'center' ? null : this.marca.id,
        "has_text": this.withText,
        "is_automatic": false,
        "brand_description": this.brandDescription.trim(),
        "category": this.withText ? this.categorySelect : null,
        "sub_category": null,
        "keywords": [
          ...this.keywords
        ],
        "ratings": [...this.ratings],
        "tone": this.toneSelect,
        "language": this.languageSelect,
        "length": this.lengthSelect,
        "include_google_username": this.googleText,
        "include_business_name": this.brandName,
        "contact_email": this.contactEmail
      }

      try {
        const {data} = this.initialData ? await FeedbackSrv.updateAutomaticAnswer(this.initialData.id, form) : await FeedbackSrv.createAutomaticAnswer(form)
        const formatedStars = data.ratings.map(item => {
          return item
        })
        this.$emit(this.initialData ? 'handle-edition' : 'handle-creation', {...data, ratings: formatedStars})
        this.close()
      } catch (error) {
        if (error?.messageError) {
          this.errorMessage = error.messageError
        }
        this.showError = true
      } finally {
        this.loading = false
      }
    }
  },
  watch: {
    email(value) {
      if (!value) {
        this.errors.email = false
      }
    },
    withText(value) {
      this.noText = !value
      if (!value) {
        this.languageSelect = 'spanish'
        this.languages = [
          {name: this.$gettext('Español'), id: 'spanish'},
          {name: this.$gettext('English'), id: 'english'}
        ]
      } else {
        this.languageSelect = 'original'
        this.languages = [
          {name: this.$gettext('Original'), id: 'original'},
          {name: this.$gettext('Español'), id: 'spanish'},
          {name: this.$gettext('English'), id: 'english'}
        ]
      }
    },
    noText(value) {
      this.withText = !value
    },
    actionSelect(value) {
      console.log({actionSelect: value})
    }
  },
}
</script>

<style scoped>
.main {
  position: fixed;
  z-index: 10;
}

.close-button {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background-color: transparent !important;
}

.selectCustomExt {
  min-height: 40px;
}

.primary-button {
  background-color: #00ADFC;
}

.primary-button span {
  color: white
}

.outlined-button span {
  color: #4f4f4f;
  width: 100%;
}

.outlined-button {
  border: 1px solid #9B9B9B;
  border-radius: 5px;
}

.outlined-button, .primary-button {
  padding: 10px 20px;
  width: 100%;
  border-radius: 5px;
}

.caption {
  color: #8A8A8A;
}

.stars-container {
  background-color: #E8E9ED;
  border-radius: 4px;
}

input {
  border: #D7DAE2 solid 1px;
  height: 40px;
  padding: 0 10px;
}

textarea {
  border: #D7DAE2 solid 1px;
  border-radius: 5px;
  padding: 10px;
}

.input-error {
  border: red solid 1px;
}

.strong-divider {
  border-bottom: 1px solid #707070;
}

.divider {
  border-bottom: 1px solid #CECECE;
}

.divider, .strong-divider {
  padding: 6px 0;
}

.configName {
  width: 70%;
}
</style>
