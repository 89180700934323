<script>
import SrvLocal from '@/services/local.js'
import commonMixins from "@/mixins/common.js";

export default {
  name: 'syncGoogleMultiple',
  props: ['visible', 'itemsBusinessChecked'],
  mixins: [commonMixins],
  data() {
    return {
      loading: false,
      imgClose: require('@/assets/img/ico-close-edit.svg'),
      name: '',
      image: null,
      showConfirm: false,
      showOk: false,
      data: {},
      errors: [],
      showErrors: false
    }
  },
  watch: {
    visible(newValue) {
      if (newValue) {
        this.image = null

      }
    }
  },
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.errors = []
          this.$emit('close')
        }
      }
    }
  },
  methods: {
    async sync() {
      this.loading = true;

      const promises = this.itemsBusinessChecked.map(async (item) => {
        try {
          const {data} = await SrvLocal.syncBunsiness(item.business_id)
          if (!data.success) {
            this.errors.push({error: {...data.error}, item: item})
          }
        } catch (error) {
          this.errors.push({error: {message: this.$gettext('Ha ocurrido un error')}, item: item})
        }
      })
      await Promise.all(promises)

      this.loading = false;
      if (this.errors.length) {
        this.showErrors = true
      } else {
        this.show = false
      }
    }
  },
}
</script>

<template lang="pug">
  div
    v-dialog.dialogEdit(v-model="show"
      width="680")
      v-card.dialogEdit
        v-card-text.pb-0
        v-container
          div.gr-loader-snipper--wrapper(v-if="loading")
            span.gr-loader-snipper
          v-row#headerModalEdit.pt-3
            span.py-2.px-4.text-14(v-if="errors.length>0") #[translate Errores sincronizando datos de Google]
            span.py-2.px-4.text-14(v-else) #[translate Sincronizar datos de Google]
          v-row#bodyModalEdit
            v-col(cols="12")
              v-container(v-if="errors.length === 0")
                v-row.py-2.px-4
                  span.text-14(v-if="isGenericEntity" ) #[translate ¿Estás seguro de que quieres sincronizar los datos de Google de estos negocios?]
                  span.text-14 #[translate ¿Estás seguro de que quieres sincronizar los datos de Google de estos restaurantes?]
                v-row.d-flex.flex-col.py-2.px-4
                  v-list-item(v-if="loading")
                    v-list-item-content
                      v-list-item-title.text-14 {{ $gettext('Sincronizando datos...') }}}
                  v-list-item(v-else v-for="item in itemsBusinessChecked" :key="item")
                    v-list-item-content
                      v-list-item-title.text-14 {{ item.center }}
                      v-list-item-subtitle.text-12 {{ item.locality }}
              v-container(v-else)
                v-row.errors(v-for="error in errors" :key="`error_${error.item.center_id}`")
                  v-col(cols="12")
                    span.text-semibold {{error.item.center}} - {{error.item.locality}}:
                    span.px-2 {{error.error.message}}


          v-row#footerModalEdit
            v-col(cols="12")
              button.btnCancelEditModal(@click.stop="show=false; errors=[]") #[translate Cancel]
              button.btnSaveEditModal(@click="showConfirm = true; sync()" v-if="errors.length === 0" )  #[translate Sincronizar]
</template>
